.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 120px!important;
}

.container {
    height: 430px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.card {
    width: 80px;
    border-radius: .75rem;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    border-radius: 2rem;
    margin: 0 10px;
    display: flex;
    align-items: flex-start;
    transition: .6s cubic-bezier(.28,-0.03,0,.99);
    box-shadow: 0px 10px 30px -5px rgba(0,0,0,0.8);
}

.card > .row {
    color: white;
    display: flex;
    flex-wrap: nowrap;
}

.card > .row > .icon {
    background: #223;
    color: white;
    border-radius: 50%;
    width: 50px;
    max-width: 50px;
    max-height: 50px;
    min-width: 50px!important;
    min-height: 50px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.card > .row > .description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    height: 80px;
    width: 520px;
    opacity: 0;
    transform: translateY(30px);
    transition-delay: .3s;
    transition: all .3s ease;
}

.description {
    border-radius: 25px;
    margin-top: 24px;
    margin-bottom: 12px;
    background-color: rgba(0, 0, 0, 0.30);
    padding: 8px 32px 8px 24px;
}

.description p {
    color: white;
    padding-top: 5px;
    font-size: 18px;
    line-height: 24px;
}

input {
    display: none;
}

input:checked + label {
    width: 600px;
    align-items: flex-start;
}

input:checked + label .description {
    opacity: 1 !important;
    transform: translateY(0) !important;
    height: 100%!important;
    width: 450px!important;
}

@media screen and (max-width: 980px) {
    .container {
        flex-direction: column;
        height: auto;
        width: 90%;
        align-items: center;
    }
    .card {
        margin: 10px 0;
        width: 600px;
        height: 70px;
        max-width: 100%;
        transition:none;
    }
    input:checked + label {
        height: 100%!important;
    }
    .row {
        width: 100% !important;
        flex-direction: column;
    }
    .card > .row > .icon {
        margin: 8px 0px 0px 8px;
    }
    input:checked + label .description {
        width: 92%!important;
        padding: 0 4%;
        margin: 12px 0 0;
    }
  }

  .description p {
    color: white;
    padding-top: 5px;
    font-size: 18px;
    line-height: 24px;
}


  @media screen and (max-width: 700px) {
    /* .row {
        max-width: 95%;
    } */
    .description p {
        font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
        margin-bottom: 180px;
    }
  }

  @media screen and (max-width: 520px) {
    .description h2 {
        font-size: 22px;
        line-height: 24px;
    }
  }

.card[for="c1"] {
    background-image: url('../images/background1-section0.png');
}
.card[for="c2"] {
    background-image: url('../images/background1-section0.png');
}
.card[for="c3"] {
    background-image: url('../images/background1-section0.png');
}
.card[for="c4"] {
    background-image: url('../images/background1-section0.png');
}