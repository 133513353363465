:root {
  --speed: 500ms;
}

body {
  font-family: "Lato", sans-serif;
  src: local('Lato'), url('./assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

button {
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 50px;
}

h2 {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}


@media screen and (max-width: 900px) {
  h1 {
    font-size: 40px;
  }
}