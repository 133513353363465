.menu-container {
    margin-top: 3%;
}

@media screen and (max-width: 600px) {
    .menu-container {
        margin-top: 7%;
    }
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    background: #A92D2C;
    margin: 0 24px;
    border-radius: 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    height: 80px;
  }
  
  .menu-logo {
    margin: 0 24px;
  }

  .menu-logo img {
    height: 110px;
  }
  
  .menu-links {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 8px;
    margin: 0 16px;
  }
  
  .menu-links a {
    text-align: center;
    text-decoration: none;
    color: white;
    padding: 8px 8px;
    margin: 0 24px;
    font-size: 18px!important;
  }
  
  .menu-links a:hover {
    color: black;
  }

  .menu-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 8px;
  }

  .icon-button img {
    height: 40px;
  }
  
  .menu-button-buy {
    display: none;
    justify-content: center;
    align-items: center;
  }

  .buy-token-button {
    background: black;
    color: white;
    font-size: 18px;
    border: none;
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 40px;
  }
  

  .hamburger-menu, .menu-links-traderjoe {
    display: none;
    cursor: pointer;
  }


  @media screen and (max-width: 800px) {
    .menu-actions, .menu-links {
      display: none;
    }

    .hamburger-menu, .menu-button-buy {
      display: block;
    }
  }

  @media screen and (max-width: 630px) {
    .menu-links {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    .menu-logo {
      margin: 0 12px 0;
    }
    .menu {
      padding: 0;
      margin: 0 12px;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 140px;
    right: 20px;
    background-color: #A92D2C;
    border-radius: 30px;
    padding: 10px 20px;
    width: 250px;
    line-height: 1.2rem;
    z-index: 9999;
  }

  @media screen and (max-width: 900px) {
    .dropdown-menu {
        top: 120px;
      }
  }

  .dropdown-menu::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 20px;
    height: 20px;
    width: 20px;
    background: #A92D2C;
    transform: rotate(45deg);
  }

  .dropdown-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
  }

  .dropdown-menu.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: var(--speed) ease;
  }

  .dropdown-menu ul li {
    padding: 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  .dropdown-menu ul a {
    padding-top: 6px;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }

  .dropdown-menu ul li:hover a {
    color: black;
  }

  .dropdown-menu ul li:hover img {
    opacity: 1;
    cursor: pointer;
  }

  .dropdown-item {
    display: flex;
    margin: 10px auto;
  }

  .dropdown-item img {
    max-width: 30px;
    margin-right: 10px;
    opacity: 0.75;
    transition: var(--speed);
  }

  h3 span {
    font-size: 14px;
    font-weight: 400;
  }