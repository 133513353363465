.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    padding: 0 0 ;
}

  .sponsor-container img {
    max-width: 240px !important;
  }

  @media screen and (max-width: 600px) {
    .sponsor-container img {
        max-width: 240px!important;
      }
  }

  @media screen and (max-width: 480px) {
    .sponsor-container img {
        max-width: 180px!important;
      }
  }

  @media screen and (max-width: 360px) {
    .sponsor-container img {
        max-width: 160px!important;
      }
  }