.home-presentation-container {
    display: flex;
    width: 80%;
    margin: 12px auto;
    overflow: hidden;
  }
  
  .home-presentation-image img {
    width: 100%;
  }
  
  .home-presentation-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px auto 16px;
}
  
  .home-presentation-intro h1,
  .home-presentation-intro p {
    margin: 0;
    padding: 16px;
    color: white;
    line-height: 32px;
  }

  .home-presentation-intro p {
    font-size: 24px;
  }

  @media screen and (max-width: 980px) {
    .home-presentation-container, .home-presentation-intro {
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: 900px) {
    .home-presentation-intro p {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .home-presentation-intro {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 600px) {
    .home-presentation-intro p {
      font-size: 18px;
    }
  }
